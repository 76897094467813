/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useEffect } from "react"
import { Box, Heading, ResponsiveContext, Text } from "grommet"
import { Transition } from "../components/_shared_/transition"
import HeaderLayout from "../components/_shared_/header"
import { Container } from "../styles/components/container"
import GrommetWrapper from "../components/_shared_/grommet"
import SEO from "../components/_shared_/seo"
import Trail from "../components/_shared_/trail"
import { mainBlack, mainBlue } from "../styles/constants"
import FooterLayout from "../components/_shared_/footer"
import FadeIn from "../components/_shared_/fade-in"
import Cookies from "../components/_shared_/cookies"

interface COOKIESProps {
  number: string
  title: string
  description: string | ReactElement
}

const COOKIES: COOKIESProps[] = [
  {
    number: `01`,
    title: `Cookies a ich používanie na stránke slovacc.sk`,
    description: `Táto webová lokalita používa súbory cookies. Prehliadaním týchto stránok súhlasíte s ich používaním. Väčšina prehliadačov umožňuje odmietnuť súbory cookies a odstrániť súbory cookies.`,
  },
  {
    number: `02`,
    title: `O súboroch cookies`,
    description: `Cookie je súbor obsahujúci identifikátor (reťazec písmen a čísel), ktorý posiela webový server do webového prehliadača a je uložený prehliadačom. Identifikátor sa potom pošle späť na server vždy, keď prehliadač požiada o stránku zo servera. Súbory cookies zvyčajne neobsahujú žiadne informácie, ktoré osobne identifikujú používateľa, ale osobné informácie, ktoré ukladáme o vás, môžu byť prepojené s informáciami uloženými v cookies a získanými z nich`,
  },
  {
    number: `03`,
    title: `Typy cookies na slovacc.sk`,
    description: (
      <Box>
        V súčasnosti používame cookies niekoľkými spôsobmi.
        <Box margin={{ bottom: `12px` }}>
          <b>Cookies podľa ich platnosti:</b>
          <ul>
            <li>
              Krátkodobé (session): sú platné počas trvania vašej návštevy a vypršia po niekoľkých minútach po opustení
              stránky. Prispievajú k dosiahnutiu základnej funkcionality samotného webu.
            </li>
            <li>
              Dlhodobé (persistent): sú platné niekoľko dní alebo mesiacov. Umožňujú vám jednoduchšie nastaviť stránky,
              keď sa vrátite, pomáhajú pri zapamätaní si konkrétneho procesu.
            </li>
          </ul>
        </Box>
        <Box>
          <b>Cookies podľa ich účelu:</b>
          <ul>
            <li>
              Kľúčové (funkčné) súbory cookies: bez nich web nebude správne fungovať. Môžete sa stretnúť s problémami s
              formulármi alebo výpočtami.
            </li>
            <li>
              Analytické: umožňujú sledovať návštevnosť stránok a používať rôzne funkcie. Podľa nich zlepšujeme
              štruktúru, formu a obsah slovacc.sk.
            </li>
          </ul>
        </Box>
      </Box>
    ),
  },
  {
    number: `04`,
    title: `Cookies používané našimi poskytovateľmi služieb`,
    description: (
      <Box>
        <Box margin={{ bottom: `12px` }}>
          Naši poskytovatelia služieb používajú súbory cookies a tieto cookies môžu byť uložené vo vašom počítači pri
          návšteve našich webových stránok. Služby obsiahnuté v tejto časti umožňujú Vlastníkovi sledovať a analyzovať
          návštevnosť na webe a môžu byť použité na sledovanie správania používateľov.
        </Box>
        <Box margin={{ bottom: `12px` }}>
          <b>Google Analytics s anonymizovanou IP (Google Inc.)</b>
        </Box>
        <Box margin={{ bottom: `12px` }}>
          Google Analytics je webová analytická služba poskytovaná spoločnosťou Google Inc. (“Google”). Google využíva
          zhromáždené údaje ku monitorovaniu a skúmaniu využití tejto Aplikácie, k príprave reportov o svojej činnosti a
          ich zdieľanie s ostatnými službami Google. Google môže zhromažďované údaje použiť k lepšiemu prispôsobeniu
          reklamy v rámci vlastnej reklamnej siete. Táto integrácia služby Google Analytics anonymizuje vašu IP adresu.
          Funguje tak, že skracuje adresy IP používateľov v členských štátoch Európskej únie alebo v iných zmluvných
          štátoch Dohody o Európskom hospodárskom priestore. Len vo výnimočných prípadoch sa úplná adresa IP odošle na
          server Google a skráti sa v rámci USA. Zhromažďované osobné údaje: Súbory cookies a údaje o používaní. Miesto
          spracovania: Spojené štáty – Zásady ochrany osobných údajov – Odhlásiť. Účastník Privacy Shield. Platnosť: 24
          mesiacov.
        </Box>
        <Box margin={{ bottom: `12px` }}>
          <b>Rozšírenie zobrazovania reklám pre Google Analytics (Google)</b>
        </Box>
        <Box margin={{ bottom: `12px` }}>
          Google Analytics môže na tejto web stránke využívať reklamné záujmy spoločnosti Google, dáta tretích strán a
          informácie z DoubleClick súborov cookies s cieľom rozšíriť analytiku o demografické údaje, záujmy a údaje z
          reklamných interakcií. Zhromažďované osobné údaje: Súbory cookies a údaje o používaní. Miesto spracovania:
          Spojené štáty – Zásady ochrany osobných údajov – Odhlásiť. Účastník Privacy Shield. Platnosť: 13 mesiacov.
        </Box>
      </Box>
    ),
  },

  {
    number: `05`,
    title: `Spravovanie súborov cookies`,
    description: (
      <Box>
        Väčšina prehliadačov vám umožňuje odmietnuť súbory cookies a odstrániť súbory cookies. Metódy na ich vykonávanie
        sa líšia v závislosti od prehliadača a jeho verzie. Aktuálne informácie o blokovaní a odstraňovaní súborov
        cookies môžete získať prostredníctvom stránky aboutcookies.org.
      </Box>
    ),
  },
  {
    number: `06`,
    title: `Vlastník a správca osobných údajov`,
    description: (
      <Box>
        Prevádzkovateľ a správca osobných údajov (ďalej len “Správca”)
        <br /> Obchodné meno: SlovAcc, s. r. o. <br />
        Právna forma: Spol. s r. o. <br />
        Address: Ulica pri Čiernej vode 5446/44, 903 01 Senec <br />
        IČO: 51825724
      </Box>
    ),
  },
]

const CookiesUsage: React.FunctionComponent = () => {
  useEffect(() => window.scrollTo(0, 0), [])
  return (
    <GrommetWrapper>
      <SEO title="Používanie súborov cookies" />
      <Cookies />
      <ResponsiveContext.Consumer>
        {(size: string): JSX.Element => (
          <>
            <HeaderLayout size={size} />
            <Transition>
              <Container>
                <Box justify="center" align="center" height="320px">
                  <Heading
                    margin={{
                      vertical: `0`,
                      horizontal: `0`,
                    }}
                    className="relative"
                    style={{ top: `70px` }}
                    size="medium"
                  >
                    {size !== `small` && <Trail text="Používanie súborov cookies" />}
                    {size === `small` && `Používanie súborov cookies`}
                  </Heading>
                </Box>
                <FadeIn>
                  {COOKIES.map(({ number, title, description }: COOKIESProps) => (
                    <Box key={number} margin={{ bottom: `24px` }}>
                      <Heading margin={{ bottom: `12px` }} size="large" color={mainBlue}>
                        {number}
                      </Heading>
                      <Text margin={{ vertical: `8px` }} size="xlarge" weight="bold" color={mainBlack}>
                        {title}
                      </Text>
                      <Text size="medium" color={mainBlack}>
                        {description}
                      </Text>
                    </Box>
                  ))}
                </FadeIn>
              </Container>
              <Container minHeight="auto" fluid>
                <FooterLayout size={size} />
              </Container>
            </Transition>
          </>
        )}
      </ResponsiveContext.Consumer>
    </GrommetWrapper>
  )
}

export default CookiesUsage
